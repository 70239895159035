<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!--加班的定义  -->
              <div class="range" id="jbddy">
                <h4 class="title">加班的定义</h4>
                <el-divider></el-divider>
                <p class="describe">
                  正常工作时间以外，公司因工作需要安排员工延时工作或者双休日、国家法定假期期间工作。
                  员工自愿延长工作时间，不作为加班。
                </p>
              </div>
              <!-- 加班的时间 -->
              <div class="range" id="jbdsj">
                <h4 class="title">加班的时间</h4>
                <el-divider></el-divider>
                <p class="describe">
                  加班最小计时单位0.5小时，延时加班从18点开始统计。
                  延时加班，一般每日不得超过1小时，因特殊原因需要不得超过3小时，但是每月扣除调休后不得超过36小时（遇自然灾害和紧急抢修除外）。
                </p>
              </div>

              <!--加班费的支付标准 -->
              <div class="range" id="jbfdzfbz">
                <h4 class="title">加班费的支付标准</h4>
                <el-divider></el-divider>
                <ul>
                  <li>
                    <p class="describe">
                      1、延时加班的，支付不低于工资150%的工资报酬；
                    </p>
                  </li>

                  <li>
                    <p class="describe">
                      2、休息日加班又不能安排补休的，支付不低于工资200%的报酬。
                    </p>
                  </li>
                  <li>
                    <p class="describe">
                      3、法定休假日加班的，支付不低于工资300%的工资报酬。
                    </p>
                  </li>
                </ul>
              </div>
              <!-- 加班的审批 -->
              <div class="range" id="jbdsp">
                <h4 class="title">加班的审批</h4>
                <el-divider></el-divider>
                <p class="describe">
                  申请加班需按照钉钉流程提交，并注明加班人员姓名及加班内容。
                  部门员工的加班申请报办公室、部门经理和总经理审批；
                  部门经理（包括副经理）及以上人员的加班申请报办公室和总经理审批。
                  办公室负责监督加班申请的真实性，对不符合条件的申请可予以拒绝。
                </p>
              </div>
              <!-- 调休的审批 -->
              <div class="range" id="txdsp">
                <h4 class="title">调休的审批</h4>
                <el-divider></el-divider>
                <p class="describe">
                  休息日加班的给与调休，调休最小计时单位0.5小时。
                  申请调休按照钉钉流程报批
                  员工调休1天（含1天）以下的，由办公室和部门经理审批；1天以上还需报总经理审批；
                  部门经理（包括副经理）及以上人员调休，报办公室和总经理审批。
                </p>
              </div>
              <!-- 不定时工作制 -->
              <div class="range" id="bdsgzz">
                <h4 class="title">不定时工作制</h4>
                <el-divider></el-divider>
                <p class="describe">
                  由部门经理根据实际情况确定相关人员名单及具体实施方案，上报办公室备案。
                </p>
              </div>
              <!-- 加班费的支付时间 -->
              <div class="range" id="jbfdzfsj">
                <h4 class="title">加班费的支付时间</h4>
                <el-divider></el-divider>
                <p class="describe">
                  当月加班、次月结算加班费，随次月的工资一同发放。
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                <ul>
                  <li v-for="(activity, index) in lists" :key="index">
                    <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp"
                    >
                      <a
                        :href="activity.target"
                        @click="tolink(index)"
                        :class="link == index ? 'islink' : ''"
                        >{{ activity.name }}</a
                      >
                    </el-timeline-item>
                  </li>
                  <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                </ul>
              </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage:0,
      lists: [
        {
          name: "加班的定义",
          target: "#jbddy",
        },
        {
          name: "加班的时间",
          target: "#jbdsj",
        },
        {
          name: "加班费的支付标准",
          target: "#jbfdzfbz",
        },
        {
          name: "加班的审批",
          target: "#jbdsp",
        },
        {
          name: "调休的审批",
          target: "#txdsp",
        },
        {
          name: "不定时工作制",
          target: "#bdsgzz",
        },
        {
          name: "加班费的支付时间",
          target: "#jbfdzfsj",
        },
      ],
      link: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll',this.getscroll)
  },
  methods: {
    tolink(index) {
      this.link = index;
    },// 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (this.percentage <= 12) {
        this.tolink(0);
      } else if (this.percentage>12 && this.percentage<=34) {
        this.tolink(1);
      } else if(this.percentage>34  && this.percentage<=66){
        this.tolink(2);
      }else{
        this.tolink(3);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 90%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.describe::first-letter{
  font-size: 20px;
  font-weight: 700;
  text-shadow:3px 2px 2px #4fc08d;
}
</style>